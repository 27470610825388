<template>
  <div>BCRtest.vue</div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="postcss">
</style>
